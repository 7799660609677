@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

h1,
h2,
h3,
h4,
h5,
h6,
ion-title {
    font-family: 'poppins-bold';
    margin-top: 0;
    margin-bottom: 0;
    color: black;
}



// * {
//     -webkit-user-select: text;
//     -moz-user-select: text;
//     -ms-user-select: text;
//     user-select: text;
//     color: black;
// }

span,
div,
ion-label,
body,
:host {
    font-size: 15px;
    font-family: 'poppins';
}

body {
    background-color: #f5f5f5;
}

@media (max-width: 768px) {

    span,
    div,
    ion-label,
    body,
    :host {
        font-size: 14px;
    }

}

ion-header {
    background-color: white !important;
    box-shadow: unset !important;
    // border-bottom: 1px solid #d1d1d1;
}

ion-content {
    --background: #f5f5f5;
}

#meals-filtered {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: #efebeb;
}

ion-toolbar {

    // padding-top: 17px !important;
    // border-bottom-left-radius: 15px;
    // border-bottom-right-radius: 15px;
    ion-title {
        text-align: center;
    }

    ion-back-button {
        font-size: var(--ion-font-body-small);
    }

    ion-buttons {
        ion-icon {
            font-size: 1.2rem;

            &#ellipsisIcon {
                margin-left: 10px;
            }
        }
    }
}

.input-group {
    margin: 0px 0px 15px 0px;

    .input-container {
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 8px 15px;
        background-color: #f9fcff;
        border: 1px solid #c7c7c7;

        ion-icon {
            font-size: 1.3rem;
        }

        ion-input {
            font-size: var(--ion-font-body-small);
            padding: 0;
            --padding-top: 0;
            --padding-bottom: 0;
        }

        #passwordToggle {
            color: grey;
        }
    }
}

.container {
    padding: 0px 7px;
}

.my-btn {
    background-color: var(--ion-color-tertiary);
    height: 40px;
    max-width: 220px;
    min-width: 200px;
    text-transform: capitalize;
    font-size: 1rem;
    font-family: "poppins-med";
    cursor: pointer;
}

.custom-btn {
    background-color: var(--ion-color-tertiary);
    border-radius: 14px;
    font-size: var(--ion-font-body);
    color: black;
    font-weight: 500;
    width: 60%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    outline: none;
    margin: .8rem auto;
    max-width: 230px;
    min-width: 160px;
    text-transform: capitalize;
    font-family: "poppins-med" !important;

    &.invalid-btn {
        opacity: 0.4;
        box-shadow: none;
    }

    &.medium-btn {
        box-shadow: none;
        width: 200px;
        margin-top: 20px;
        background-color: #ffebcc;
    }

    &:hover {
        font-weight: bold;
    }
}

.custom-btn-100 {
    background-color: var(--ion-orange);
    border-radius: 4px;
    font-size: var(--ion-font-body-small);
    color: black;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.invalid-btn {
        opacity: 0.4;
        box-shadow: none;
    }
}

.custom-btn-96 {
    background-color: #f1f1f1;
    border-radius: 4px;
    font-size: var(--ion-font-body-smaller);
    padding: .6rem .41rem;
    max-width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.custom-btn-light {
    background-color: #ffd443;
    border-radius: 14px;
    font-size: var(--ion-font-body-small);
    color: #000000;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem auto;
    padding: 1rem;
    box-shadow: 0 5px 8px #cbc9c9;
}

.custom-btn-small {
    background-color: var(--ion-color-tertiary);
    border-radius: 14px;
    font-size: var(--ion-font-body-small);
    color: black;
    font-weight: bold;
    margin: .4rem auto;
    padding: .6rem 1.5rem;
    box-shadow: 0 4px 4px #814c2082;
}

.btn-link {
    background-color: transparent;
    font-size: var(--ion-font-body-small);
    color: var(--ion-orange);
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
    max-height: 23px;
}

.green-link {
    color: #14ac1d;
    text-decoration: underline;
    cursor: pointer;
}


.btn-link-2 {
    background-color: transparent;
    font-size: var(--ion-font-body-small);
    font-weight: 700;
    color: var(--ion-orange);
    text-decoration: underline;
    cursor: pointer;
}

.btn-link-3 {
    font-size: var(--ion-font-body-small);
    color: #3e94ec;
    text-decoration: underline;
    cursor: pointer;
}

.section-block {
    padding: 15px;
    margin: 20px;
    text-align: center;

    .subtitle {
        font-weight: bold;
        color: var(--ion-color-secondary);
        font-size: var(--ion-font-body);
    }

    .white-block {
        background-color: white;
        border-radius: 10px;
        border: 1px solid white;
        padding: 5px 10px;
        margin-top: 20px;

        p {
            color: #677187;
        }

        &.inactive {
            border: 1px solid red;
        }
    }
}

.marg-05 {
    margin: .5rem !important;
}

.marg-1 {
    margin: 1rem !important
}

.marg-2 {
    margin: 2rem
}

.bg-light {
    background-color: #f5f5f5;
}

.margin-top-02 {
    margin-top: .2rem;
}

.margin-top-05 {
    margin-top: .5rem !important;
}

.margin-top-1 {
    margin-top: 1rem !important;
}

.margin-top-1-5 {
    margin-top: 1rem !important;
}


.margin-top-2 {
    margin-top: 2rem !important;
}

.margin-top-3 {
    margin-top: 3rem !important;
}

.margin-top-4 {
    margin-top: 4rem !important;
}

.margin-top-5 {
    margin-top: 5rem !important;
}

.margin-top-6 {
    margin-top: 3rem !important;
}

.margin-top-8 {
    margin-top: 8rem !important;
}

.margin-top-9 {
    margin-top: 9rem !important;
}

.margin-top-10 {
    margin-top: 10rem !important;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-bottom-3 {
    margin-bottom: 3rem;
}

.margin-bottom-6 {
    padding-bottom: 6rem;
}

.padding-0 {
    padding: 0;
}

.padding-0-5 {
    padding: .5rem;
}

.padding-1 {
    padding: 1rem;
}

.padding-2 {
    padding: 1rem 2rem !important;
}

.padding-bottom-1 {
    padding-bottom: 1rem;
}

.padding-bottom-2 {
    padding-bottom: 2rem;
}

.padding-bottom-4 {
    padding-bottom: 4rem;
}

.padding-bottom-14 {
    padding-bottom: 14rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-4 {
    margin-left: 4rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-right-2 {
    margin-right: 2rem;
}

.margin-right-05 {
    margin-right: .5rem;
}

.margin-left-05 {
    margin-left: .5rem;
}

.nav-icon-container {
    width: 30px;
    height: 30px;
    background-color: var(--ion-orange-dark);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    margin: 0 auto;

    fa-icon {
        font-size: 0.9rem;
    }
}

#navSnippet {
    display: flex;
    justify-content: center;
    align-items: center;

    div {
        padding: 0.7rem;
        cursor: pointer;

        &.selected {
            color: var(--ion-color-primary);
        }
    }
}

.input-group-row {
    display: flex;
    justify-content: space-between;

    .input-group {
        width: 48%;
    }
}

#autoComplete {
    position: relative;

    #autoCompleteList {
        box-shadow: 0px 0px 4px rgb(179, 179, 179);
        border-radius: 8px;
        position: absolute;
        background: #383838;
        z-index: 100;
        width: 100%;
        max-height: 225px;
        overflow: auto;
        color: white;

        .auto-complete-item {
            padding: 8px 24px;
            font-size: var(--ion-font-body-small);

            .line-1 {
                color: var(--ion-red);
                font-size: var(--ion-font-body-small);
            }

            .line-2 {
                color: gray;
                font-size: var(--ion-font-body-small);
            }
        }
    }
}

.grey-text {
    color: grey;
}

.tag-item {
    font-size: var(--ion-font-body-small);
    margin: 2px 9px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    img {
        object-fit: contain;
        width: 80px;
        height: 70px;
        margin: 0.3rem 1.2rem;
        max-width: unset;
        border-radius: 50%;
        box-sizing: border-box;
        cursor: pointer;
    }

    &:last-child {
        margin-right: 0px;
    }

    &.active,
    &.hover {

        border-bottom: 2px solid var(--ion-orange);
        background-color: #fdf6e7;

        img {
            //     border: 1px solid var(--ion-orange);

        }
    }
}


.tag-item-tbc {
    font-size: var(--ion-font-body-small);
    margin: 2px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    div {
        padding: .3rem .15rem;
        border-radius: 5px;
    }

    img {
        object-fit: cover;
        width: 80px;
        height: 80px;
        margin: 0.3rem 1.2rem;
        max-width: unset;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #bdb3b3;
        cursor: pointer;
    }

    &:last-child {
        margin-right: 0px;
    }

    &.active {
        // border-bottom: 3px solid #ffe150;

        img {
            border: 3px solid var(--ion-orange);
        }

        div {
            background-color: var(--ion-orange);
            color: black;
        }
    }
}

.loader {
    width: 100%;
    display: flex;
    flex-direction: column;
}

/// Loader
.lds-ellipsis {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 3rem;

    div {
        position: relative;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: var(--ion-orange);
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis1 0.6s infinite;
        }

        &:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis2 0.6s infinite;
        }

        &:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis3 0.6s infinite;
        }
    }
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(24px, 0);
    }
}

#logo {
    width: 100%;
    max-width: 250px;
    min-width: 100px;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    top: 0;
}

#logo-mob {
    // max-height: 60px;
    cursor: pointer;
    width: 260px;
}

.border {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 6px;
    padding: .4rem;
}

.border-1 {
    border: 1px solid rgb(201, 201, 201);
}

.bord {
    border: 1px solid rgb(201, 201, 201);
    border-radius: 6px;
}

.border-red {
    border: 1px solid var(--ion-red);
    border-radius: 6px;
    padding: .4rem;
}

.border-range {
    border: 1px solid var(--ion-orange)
}

.border-bottom {
    border-bottom: 1px solid rgb(233, 233, 233);
}

.border-bottom-2 {
    border-bottom: 1px solid rgb(194, 194, 194);
}

.border-bot {
    border-bottom: 1px dotted rgb(204, 204, 204);
}

.border-top {
    border-top: 1px solid rgb(204, 204, 204);
}

#main-div {
    text-align: left;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
}

.modal-wrapper.sc-ion-modal-md {
    max-width: 1200px;
    width: 84%;
    height: 96%;
}

.center {
    text-align: center;
}

.left {
    text-align: left;

}

.right {
    text-align: right !important;
}

.top-text {
    display: flex;
    justify-content: center;
}

.width-100 {
    width: 100%;
}

.height-100 {
    height: 100%;
}

.height-500 {
    height: 500px;
}

#banner {
    background-repeat: no-repeat;
    background-size: cover;
    height: 20rem;
    width: 100%;
}

.top-menu {
    background-color: #ffffff70;
    margin: 0 1rem;

    ion-col {
        display: flex;
        align-items: center;
    }
}

.menu-ion-icon {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
}

.profile-ion-icon {
    color: #747474;
    font-size: 1.3rem;
    margin: 0.3rem 0.7rem 0;
    padding: 0.2rem;
    border-radius: 50%;
    border: 1px solid #a3a3a3;
}


.ion-icon {
    color: var(--ion-orange);
    font-size: 1.2rem;
    font-weight: bold;
    margin: .7rem 1rem 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meal,
.plan-item {
    img {
        object-fit: cover;
        height: 230px;
        width: 100%;
        //  border-bottom: 1px solid rgb(150, 150, 150);
    }

    .img-radius {
        /*
        border-bottom: 2px solid white !important;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        box-shadow: 0 5px 10px #8b89898a;
        */
    }
}

.width-1024 {
    max-width: 1500px !important;
    margin: .5rem auto 2rem auto;
}

.width-perc {
    max-width: 1240px !important;
    margin: .4rem auto;
}

.container-perc {
    width: 96% !important;
    max-width: 1250px;
    margin: 2rem auto;
    // background-color: rgb(238, 238, 238);
    border-radius: 10px;
    padding: .21rem;
}

.width-perc-2 {
    margin-left: auto;
    margin-right: auto;
}

.vert-center {
    display: flex;
    align-items: center;
}


.max-width {
    max-width: 700px;
}

.max-width-360 {
    max-width: 360px;
}

.max-width-220 {
    max-width: 220px;
}

.max-width-1430 {
    max-width: 1430px;
    margin: 0 auto;
}

.max-width-1600 {
    max-width: 1600px;
    margin: 0 auto;
}

.max-width-800 {
    max-width: 800px;
    margin: 0 auto;
}

.margin-center {
    margin: 0 auto;
}

.footer-bg {
    background-color: rgb(255 243 215 / 80%);
    padding-top: .3rem;
}

.bglight {
    background-color: #ddd;
    padding: .4rem 1rem;
    border-radius: 10px;
}

.flex {
    display: flex;
}

.flex-dir-col {
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-row-wrap-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.flex-col-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-rowstart {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.flex-rowsbase {
    display: flex;
    align-items: baseline;
    flex-direction: row;
}

.flex-rowcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.flex-rowbtw {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-rowbtw-start {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-rowend {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}


.flex-colcenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flex-colend {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column !important;
}

.flex-colend-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-colstart {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column !important;
}

.orange-btn {
    --background: var(--ion-orange);
    max-width: 200px;
    font-weight: 500;
    text-transform: initial;

    &.invalid-btn {
        opacity: 0.6;
        --background: #b9b9b9;
        box-shadow: none;
        font-weight: 600;
    }
}

.grey-btn {
    --background: #ededed;
    max-width: 150px;
    font-weight: 500;
    text-transform: initial;
    --color: #868686;

    height: 28px;
    font-size: var(--ion-font-body-small);
}

.orange-btn-clear {
    font-weight: 500;
    text-transform: initial;
    font-size: var(--ion-font-body-small);
    color: var(--ion-orange-dark);
}

.flex-col-centers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-colstart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-col-right {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;

}

.flex-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flex-col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.flex-btw {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-btwstart {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-base {
    display: flex;
    justify-content: baseline;
    align-items: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center
}


.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center
}

.col-child {
    margin: .5rem auto;
    width: 180px
}

a {
    cursor: pointer !important;
}

.cursor {
    cursor: pointer !important;
}

.curve {
    border-radius: 5px;
}

.divider {
    border-bottom: 2px solid var(--ion-orange);
}

.bc-icon {
    background-color: transparent !important;
    padding: 0 !important;
}

.premium-tag {
    background-color: var(--ion-orange);
    color: white;
    padding: .2rem .8rem;
    position: absolute;
    z-index: 100;
    right: 50px;
    top: -1rem;
    font-size: var(--ion-font-body-small);
    border-radius: 5px;
}

.premium-tag-bot {
    background-color: black;
    color: var(--ion-orange);
    padding: .2rem .8rem;
    position: absolute;
    z-index: 100;
    top: -1rem;
    font-size: var(--ion-font-body-small);
    border-radius: 12px;
    border: 1px solid white;
}

.prem {
    background-color: var(--ion-orange);
    color: rgb(0, 0, 0);
    font-weight: 700;
    font-size: var(--ion-font-body-small);
    margin-left: .4rem;
    border-radius: 7px;
    padding: .14rem
}

.premium {
    background-color: black;
    color: white;
    padding: .5rem;
}

.show-all {
    margin: .7rem;
    color: var(--ion-orange);
    text-decoration: underline;
    font-weight: 700;
    text-align: right;
    white-space: nowrap;
    cursor: pointer;
}

.a-link {
    color: var(--ion-orange);
    text-decoration: underline;
    font-weight: 700;
    cursor: pointer;
}

.a-link-2 {
    color: var(--ion-orange);
    margin: 0 .4rem;
    // font-weight: 700;
    cursor: pointer;
}


.a-link-3 {
    color: black;
    margin: 0 .4rem;
    cursor: pointer;
}


.ul-il {
    li {
        font-size: var(--ion-font-body);
    }
}

.underline {
    text-decoration: underline;
}

.no-underline {
    text-decoration: none;
}

.selected-card {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    div {
        img {
            width: 60px
        }
    }
}

select {
    background-color: #fcfcfc;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #d5d4d4;
    padding: 0.2rem;
    margin-top: 0.3rem;
    min-width: 130px;
    margin-left: 0.5rem;
}

.white-but {
    background-color: white;
    color: #00ba2a;
    font-size: var(--ion-font-body);
    box-shadow: 3px 3px 7px rgb(168, 168, 168);
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    padding: .5rem;
    font-weight: 700;
    border-radius: 15px;

    ion-icon {
        font-size: 2.2rem;
        margin-right: 1rem;
    }
}

.slides {
    margin-bottom: 0.4rem;
    --bullet-background: black !important;

    div {
        // width: 20%;
        // max-width: 400px;
    }
}

.close-modal {
    color: rgb(0, 0, 0);
    font-size: var(--ion-font-body);
    margin: 0;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.8rem;

    ion-icon {
        font-size: 1.5rem;
        margin-left: .7rem;
    }
}

.small-font {
    font-size: var(--ion-font-body-small);
}

.small-fonts {
    font-size: var(--ion-font-body-small) !important;
    text-transform: capitalize !important;
}

.small-font-9 {
    font-size: var(--ion-font-body-small);
}

.const {
    margin-top: 13rem;
    margin-bottom: 20rem;
}

.meal-Counter {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: var(--ion-orange-dark);
    border-radius: 50%;
    right: -44px;
    top: -10px;
    font-weight: 700;
    padding: 0 .3rem;
    color: white;
    font-size: var(--ion-font-body-small);
    z-index: 300;
}

.meal-Counter-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-orange);
    border-radius: 6px;
    font-size: var(--ion-font-body-bigger);
    max-width: 70px;
    padding: 10px;
    margin: 0 1rem;
}

.meal-Count-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ion-orange);
    border-radius: 6px;
    font-size: var(--ion-font-body);
    max-width: 70px;
    padding: 0.7rem 0.5rem;
    margin: 0 auto;
}

.rem-meal {
    position: relative;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--ion-orange);
        left: -15px;
        top: 0;
        position: absolute;
        z-index: 300;
        width: 25px;
        height: 25px;

        ion-icon {
            color: white;
            font-size: 2rem;
        }
    }
}

.my-custom-menu {
    --width: 300px;
}

.flex-right {
    position: absolute;
    height: 75%;
    right: 0;
}

.conf-div {
    width: 90% !important;
    margin: 3rem auto;
    background-color: white;
    border: 1px solid #d2d2d2;
    border-radius: 10px;
}

.vendor-image {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    border: 1px solid rgb(219, 219, 219) !important
}

.chef-image {
    width: 65px !important;
    height: 65px !important;
    border-radius: 50% !important;
    margin-right: .25rem;
    border: 1px solid rgb(219, 219, 219) !important
}

.width- {
    width: 94% !important;
}

#q-btn {
    text-align: right;
    width: 125px;

    .c-btn {
        background-color: #ffd751;
        border-radius: 5px;
        font-size: var(--ion-font-body-smaller);
        margin: 0.17rem;
        padding: 0.4rem;
        font-weight: 500;
        min-width: 2rem;
        color: #000;

        &.orange-2 {
            background-color: var(--ion-orange);
            color: white;
            font-weight: bold;
        }
    }
}

#q-btn-center {
    text-align: center;

    .c-btn {
        background-color: var(--ion-orange);
        border-radius: 5px;
        font-size: var(--ion-font-body-smaller);
        margin: 0.17rem;
        padding: 0.4rem;
        font-weight: 500;
        min-width: 2rem;

        &.orange-2 {
            background-color: var(--ion-orange);
            color: white;
            font-weight: bold;
        }
    }
}

.q-btn {
    text-align: right;

    .c-btn {
        background-color: var(--ion-orange);
        border-radius: 5px;
        font-size: var(--ion-font-body-smaller);
        margin: 0.17rem;
        padding: 0.4rem;
        font-weight: 500;
        min-width: 2rem;

        &.orange-2 {
            background-color: var(--ion-orange);
            color: white;
            font-weight: bold;
        }
    }
}

.d-btn {
    border-radius: 5px;
    font-size: var(--ion-font-body-small);
    color: rgb(255, 255, 255);
    margin: .3rem;
    padding: .5rem 1rem;
    background-color: var(--ion-orange);

}

._img {
    border-radius: 20%;
}

.outer-div {
    padding: .5rem;
    border-radius: 10px;
}

.inline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.text-area {
    max-width: 650px;
    font-size: var(--ion-font-body);
    color: black;
    border-radius: 7px;
}

textarea {
    height: 140px !important;
}

.youtube {
    width: 96%;
    height: 60%;
    text-align: center;
    margin: 5rem auto;
}

.ii {
    width: 96%;
    max-width: 650px;
    border: 1px solid rgb(209, 209, 209);
    border-radius: 7px;
}

.my-modal-class {
    --max-width: 1110px;
    --width: 96% !important;
    --height: 86% !important;
}

.my-modal-class-2 {
    --max-width: 1110px;
    --width: 96% !important;
    --max-height: 96% !important;
}

.invalid {
    background-color: rgb(187, 187, 187);
    cursor: not-allowed !important;
}

.native-input.sc-ion-input-md {
    //   background-image: linear-gradient(transparent, #f0f8ffc9);
}

.personal-info {
    min-width: 360px;
    padding-left: .5rem;
    border: 1px solid var(--ion-orange);
    border-radius: 10px;
}

.notif {
    background-color: #ffd34473;
    color: rgb(0, 0, 0);
    position: relative;
    width: 100%;
    font-size: var(--ion-font-body-small);
    min-height: 2.5rem;
    padding: .3rem;

    div {
        width: 86%;
        margin: 0 auto;
    }

    ion-icon {
        position: absolute;
        top: 0.3rem;
        right: .15rem;
        font-size: 1.8rem;
        font-weight: 700;
        color: rgb(10, 71, 10);
    }
}

.switch-meal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 2rem;

    ion-icon {
        color: rgb(129, 129, 129);
        font-size: 1.8rem;
        margin: .5rem;
    }
}

.ion-meal {
    color: #a5a5a5;
    font-size: var(--ion-font-body-small);
    margin: .5rem auto 1.5rem auto;
    border: 1px solid grey;
    padding: 3px;
    border-radius: 50%;
}

.hide-icon {
    ion-icon {
        visibility: hidden;
    }
}

.horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: auto;
    margin-bottom: -4px;
    border-top-right-radius: 15% 31%;
    border-top-left-radius: 15% 31%;
}

/* width */
.horizontal-scroll::-webkit-scrollbar {
    width: 7px;
}

/* Track */
.horizontal-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 15px;
}

/* Handle */
.horizontal-scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 166, 0, 0.342);
    border-radius: 10px;
}

/* Handle on hover */
.horizontal-scroll::-webkit-scrollbar-thumb:hover {
    background: var(--ion-orange);
}


#meal-row {
    ion-col {
        &:nth-child(1) {
            img {
                width: 100%;
                height: 70px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }
}

.f-smaller {
    font-size: var(--ion-font-body-smaller);
}

.f-small {
    font-size: var(--ion-font-body-small);
}

.f-regular {
    font-size: var(--ion-font-body);
}

.f-big {
    font-size: var(--ion-font-body-big);
}

.f-bigger {
    font-size: var(--ion-font-body-bigger);
}

.white {
    color: white !important;
}

.black {
    color: black !important;
}

.blue {
    color: rgb(1, 36, 151) !important;
}

.orange {
    color: var(--ion-orange) !important;
}

.orange-dark {
    color: red
}

.orangex {
    color: var(--ion-orange-dark) !important;
}

.red {
    color: var(--ion-red) !important;
}

.grey {
    color: grey !important;
}

.green {
    color: var(--ion-green) !important;
}

.green-dark {
    color: rgb(0, 97, 0) !important;
}


.grey-light {
    color: #8c8b8b !important;
}

.grey-lighter {
    color: #bab9b9 !important;
}


.white-dark {
    color: #ebebeb !important;
}

.strong {
    font-weight: 700;
}

.bg-white {
    background-color: white !important;
    margin: 0 auto;
    width: 80%;
    max-width: 600px;
}

.bg-black {
    background-color: black !important;
}

.bg-white-2 {
    background-color: white !important;
}

.bg-grey {
    background-color: grey !important;
}


.bg-grey-light {
    background-color: #f5f5f5 !important;
}


.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.active-vendor-circle {
    color: var(--ion-orange) !important;
}



.div1 {
    width: 100%;
    // border-bottom: 1px dotted rgb(95, 95, 95);

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.rating-1 {
    font-size: var(--ion-font-body);
    color: var(--ion-orange);
    background-color: var(--ion-orange-dark);
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.rating-2 {
    font-size: var(--ion-font-body);
    color: #ddd;
    background-color: #817e79;
    border-radius: 50%;
    padding: .1rem;
    margin: 0 .081rem;
}

.small-rating-1 {
    font-size: 14px;
}

.small-rating-2 {
    color: #d7d7d7 !important;
    font-size: 13px;
}

.rating-01 {
    font-size: var(--ion-font-body);
    color: var(--ion-orange);
    border-radius: 50%;
    margin: 0 .081rem;
}

.rating-02 {
    font-size: var(--ion-font-body);
    color: #817e79;
    border-radius: 50%;
    margin: 0 .081rem;
}

.about-chef {
    ion-icon {
        color: grey;
        margin-right: 1rem;
    }
}

.main-div-0 {
    width: 98%;
    max-width: 991px;
    // background-color: rgb(238, 236, 236);
    border-radius: 15px;
    margin: 1rem auto;
}

.order-div {
    margin: 2rem auto;
    width: 100%;
    margin-bottom: 3.5rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #d7d7d7;
    border: 1px soild #f5f5f5;
    border-radius: 6px
}

.main-div {
    margin: 1rem auto;
    width: 100%;
    margin-bottom: 2.6rem;
    background-color: white;
    border-radius: 10px;

    ion-row {
        width: 100%;
        box-shadow: 2px 2px 3px rgb(187, 187, 187);
        margin-bottom: 0.35rem;
        padding: 0;

        ion-col {
            padding: 0;

            .div-1 {
                margin: 0;
                border-radius: 10px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 250px;
                    border-radius: 10px;
                }
            }
        }
    }
}

.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    section {

        >div {
            justify-content: center;
            border-radius: 10px;
            margin: 1rem;
            background-color: white;
            width: 350px;
            margin: 1.5rem;
            padding: 0;
            border: 1px solid #dad9d9;
            box-shadow: 2px 2px 3px rgba(209, 209, 209, 0.514);
            background-color: white;

            img {
                object-fit: cover;
                width: 100%;
                height: 200px;
                border-radius: 10px 10px 0 0;
            }

            div {
                ion-icon {
                    color: var(--ion-orange);
                    margin-right: .25rem;
                }
            }
        }
    }
}

.review {
    padding: .5rem;
    margin: 1rem 0;
    background-color: white;
    // border: 1px solid rgb(221 220 220);
    border-radius: 10px;
}

.ion-segment {
    border: 1px solid #dad9d9 !important;
    border-radius: 7px;
    background-color: #fffbf2;
    padding: 0 0 .4rem 0;
}

.ion-segment-2 {
    border-radius: 7px;
    background-color: #fffbf2;
    max-width: 600px;
    font-weight: bold;
    margin: 0 auto;
}

.img-cover {
    height: 120px;
    border-radius: 50%;
    margin: .4rem auto;
}

.img-cover-2 {
    height: 30px;
    border-radius: 50%;
    margin: .2rem;
}

.img-cover-square {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 7px !important;
}

.delivery-status {
    max-width: 600px;
    margin: 1.5rem auto;
    padding: 0.2rem;
    background-color: white;
    //  box-shadow: -1px 2px 7px #b9b2b2 !important;
    border-radius: 10px;
}

.padding-1-5 {
    padding: 1.5rem
}

.padding-left-05 {
    padding-left: .5rem
}

.padding-right-05 {
    padding-right: .5rem
}

.radius {
    border-radius: 7px;
}

custom-toast {
    --color: white;
}

.div2 {
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgb(192, 192, 192);

    img {
        width: 100%;
        height: 300px;
        object-fit: cover;
    }
}

.chef-mask {
    display: flex;
    align-items: baseline;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.chef {
    align-items: flex-end;
    margin: 0 4%;
    padding: 0.2rem;
    color: white;
    width: 100%;
    position: absolute;
    bottom: 0;

    img {
        object-fit: cover;
        width: 140px;
        height: 140px;
        border-radius: 50%;
    }

    div {
        ion-icon {
            color: var(--ion-orange);
            margin-right: .25rem;
        }
    }
}

.quote {
    font-style: oblique;
    font-size: var(--ion-font-body);
    color: grey;
    padding: .7rem;
    background-color: white;
    margin: 1rem auto;
    max-width: 991px;
}

.page-menu {
    display: flex;
    flex-direction: row;
    background-color: #e4e4e4;
    height: 60px;
    overflow: hidden;
    margin: 0 auto;
    max-width: 991px;

    ion-segment {
        ion-segment-button {
            color: #666;
            font-size: var(--ion-font-body-small);
            text-transform: capitalize;
            font-weight: 700;
            cursor: pointer;
        }
    }
}

.black-but {
    display: flex;
    padding: 0.1rem;
    font-size: 2rem;
    color: white;
    position: relative;
    z-index: 2000;
}

.right-but {
    margin: 0.1rem 0.15rem 0 auto;
}

.border-bot-mobile {
    border-bottom: 0;
}

.phone-style {
    background-color: var(--ion-orange);
    padding: .4rem 0.4rem;
    border-radius: 15px;
    width: 170px;
    text-align: center;
    font-size: var(--ion-font-body);
}

.it {
    margin: .5rem auto;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
}

.sc-ion-modal-md-h:first-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.5) !important;
}

.order-header {
    box-shadow: unset !important;

    ion-col {
        padding: .5rem !important;
        background-color: #f5f5ff
    }
}

.opacity {
    opacity: .4;
}

.opacity-5 {
    opacity: .5;
}


.big-font {
    font-size: 1.4rem;
    color: black
}

.zip {
    top: -5px;
    right: 10px;
    height: 22px;
    border-radius: 4px;
    background-color: var(--ion-orange);
    display: flex;
    padding: 1px 3px;
    color: white;
    font-size: var(--ion-font-body-smaller) !important;
    cursor: pointer;

    ion-spinner {
        width: 1.15rem;
        height: 1.15rem;
    }
}

.zip-2 {
    height: 20px;
    display: flex;
    padding: 1px 3px;

    strong {
        color: #ffffff;
    }
}

.zip-input {
    color: black;
    width: 65px;
    background-color: white;
    border-radius: 3px;
}

.zip-input-mobile {
    color: black;
    width: 45px;
    background-color: white;
    width: 45px;
    border-radius: 3px;
    text-align: left;
    margin-right: 0.15rem;
}

.go-input {
    font-size: var(--ion-font-body-smaller);
    border-radius: 3px;
    margin-left: 0.11rem;
    padding: 0.2rem;
}

.alert {
    background-color: var(--ion-red);
    color: white;
    padding: 3px;
}

.yel {
    color: var(--ion-orange);
}


//--- modal div
.modalDialog {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.77);
    z-index: 99999;
    opacity: 1;
    -webkit-transition: opacity 400ms ease-in;
    -moz-transition: opacity 400ms ease-in;
    transition: opacity 400ms ease-in;
}

.modalDialog:target {
    opacity: 1;
    pointer-events: auto;
}

.modalDialog>div {
    width: 330px;
    position: relative;
    margin: 15% auto;
    padding: 5px 20px 13px 20px;
    border-radius: 10px;
    background: #ffffff;
}

.close {
    background: var(--ion-orange-dark);
    color: #FFFFFF;
    line-height: 25px;
    position: absolute;
    right: -12px;
    text-align: center;
    top: -10px;
    width: 24px;
    text-decoration: none;
    font-weight: bold;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    -moz-box-shadow: 1px 1px 3px #000;
    -webkit-box-shadow: 1px 1px 3px #000;
    box-shadow: 1px 1px 3px #000;
}

.close:hover {
    background: #00d9ff;
}

.cal-div {
    text-align: center;
    height: 400px;
    padding: 1rem;
    display: flex;
    align-items: center;
    line-height: 150%;
    font-size: var(--ion-font-body);

    img {
        width: 80px;
    }
}

.modal-btn {
    background-color: var(--ion-orange);
    border-radius: 5px;
    font-size: var(--ion-font-body-small);
    color: rgb(255, 255, 255);
    margin: .3rem;
    padding: .3rem .5rem;
    text-shadow: 1px 1px 2px #341700;
}

.title-mob {
    font-size: var(--ion-font-body-small);
    margin-left: -7px;
}

// ion-content::before {
//     --background: white !important;
// }

.tracking {
    border-radius: 5px;
    margin: 1.5rem .3rem .5rem .3rem;
    padding: .4rem .5rem;

    ion-row {
        border: unset;
        border-bottom: 1px solid rgb(219, 219, 219);
        margin-top: 1rem;
        box-shadow: unset;
    }
}

.transparent {
    background-color: transparent;
}

.q-btn-2 {
    text-align: right;

    .c-btn {
        background-color: var(--ion-orange);
        border-radius: 5px;
        font-size: var(--ion-font-body-smaller);
        color: rgb(255, 255, 255);
        margin: .3rem;
        padding: .3rem .5rem;
        text-shadow: 1px 1px 2px #341700;

        &.invalid-btn {
            opacity: 0.4;
        }

        &.medium-btn {
            width: 200px;
            margin-top: 20px;
            background-color: #ffebcc;
        }
    }
}

// ------------ scroll 
// ------------ scroll 

.scroll {
    overflow-x: scroll !important;
    overflow-y: auto !important;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background-color: #eeeded;
    border-radius: 5px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: rgb(183, 195, 204);
    border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(135, 185, 226);
}


@media (max-width: 768px) {

    .scroll::-webkit-scrollbar-track {
        background: unset !important
    }

    .scroll::-webkit-scrollbar-thumb {
        background: unset !important
    }

    .scroll::-webkit-scrollbar-thumb:hover {
        background: unset !important
    }
}

.calendar {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    font-weight: 600;
    background-color: white;
    font-family: 'poppins-bol';

    div {
        text-align: center;
        border-radius: 5px;
        min-width: 70px;
        height: 60px;
        border: 1px solid #e9e5e5;
        margin: 0.5rem 0.4rem;
        padding: 0.5rem;
        cursor: pointer;
        font-size: 12px;
        box-shadow: 2px 2px 6px #e7e7e7;
        font-weight: 100;

        :hover {
            color: rgb(199, 0, 0)
        }

        &.cal-selected {
            background-color: rgb(255, 192, 76);
        }
    }
}

.order-calendar {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--ion-font-body);
    font-weight: 600;

    :hover {
        color: var(--ion-green);
    }

    div {
        text-align: center;
        border-radius: 5px;
        min-width: 80px;
        height: 80px;
        border: 1px solid #e9e5e5;
        margin: 0.5rem 0.4rem;
        padding: 0.5rem;
        cursor: pointer;
        font-size: var(--ion-font-body-small);

        :hover {
            color: #039c10
        }

        &.cal-selected {
            background-color: var(--ion-orange);
        }
    }
}

.bg-red {
    background-color: var(--ion-red)
}

.bg-red-dark {
    background-color: #57362c;
}

.col-box {
    padding: 0.5rem 1rem;
    background-color: white;
    min-width: 300px;
    border-radius: 7px;
    box-shadow: 1px 1px 5px #c0c0c0;
    max-width: 1100px;
    margin: 1rem auto 2rem auto;
}

.col-box-tbc {
    border-radius: 10px;
    margin: 1.2rem 0.4rem;
    box-shadow: 4px 4px 10px #c5c5c5;
    background-color: white;
    width: 300px;
}

.col-box-tbc-2 {
    padding: 0 0.5rem;
    margin: .5rem auto;
    padding: 1rem
}

.col-box-2-card {
    border-radius: 10px;
    margin: 1rem;
    box-shadow: 4px 4px 10px #c5c5c5;
    background-color: white;
    width: 445px;
    min-width: 300px;
    border: 1px solid #ebebeb;
}

//-----------------------------------

.main-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--ion-font-body-small);
    font-weight: 500;
    margin: 1rem 0;
}

.main-title-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--ion-font-body-small);
    font-weight: 500;
}


.tit {
    border-bottom: 5px solid var(--ion-orange);
    font-size: var(--ion-font-body);
    font-weight: 600;
}

.hr-small {
    height: 4px;
    border: 0;
    border-radius: 5px;
    background-color: var(--ion-orange);
    width: 40px;
    margin: 0 auto;
}

.bold-0 {
    font-weight: normal;
}

.bold-500 {
    font-weight: 500;
}

.bold-600 {
    font-weight: 600;
}

.spice-lev {
    display: flex;
    align-items: center;
    font-size: var(--ion-font-body-smaller);

    img {
        // height: 17px !important;
        // width: 10px !important;
        // margin-right: .3rem;
    }
}

.spice-lev-2 {
    display: flex;
    align-items: center;
    font-size: var(--ion-font-body-smaller);

    img {
        height: 35px !important;
        width: 10px !important;
        margin-right: .3rem;
    }
}

.dietary {
    width: 16px !important;
    height: 16px !important;
    margin: 0 0.2rem;
    object-fit: unset !important;
}

.dietary-2 {
    width: 20px !important;
    height: 20px !important;
    margin: 0 0.2rem;
    object-fit: unset !important;
}

.relative {
    position: relative;
}

.fixed {
    //  position: fixed;
}

.prem2 {
    width: 40px;
    position: absolute;
    top: -2.5rem;
    right: 4rem;
}

.prem3 {
    width: 30px;
    position: absolute;
    top: 0.1rem;
    right: 0.7rem;
    z-index: 100;
}

@mixin total-cart {
    background-color: white;
    font-weight: 600;
    border-radius: 50%;
    position: absolute;
    font-size: var(--ion-font-body-smaller);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.1rem;
    width: 1.1rem;
}

.total-meals-cart {
    @include total-cart;
    top: 0.3rem;
    right: 0.3rem;
}

.total-meals-cart-2 {
    @include total-cart;
    top: 0.3rem;
    right: 0.9rem;
}

.caret {
    position: absolute;
    font-size: 2.8rem;
    color: #ededed;
    z-index: 200;
    bottom: -1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.right-margin {
    margin: 0.4rem 6.7rem 0 0.2rem !important;
    padding: 1.2rem .8rem;

    .total-meals-cart {
        top: .9rem
    }
}

.cart-img {
    height: 120px;
    width: 120px;
    object-fit: cover;
    border-radius: 15px;
}

.price {
    color: var(--ion-color-primary);
    font-weight: 500;
    text-align: right;
}

//----------------------------------------

.slider {
    -webkit-appearance: none;
    width: 140px;
    height: 2px;
    background: var(--ion-orange);
    outline: none;
    opacity: .9;
    transition: opacity 0.2s;
    border-radius: 30px;
    margin: 0 0.3rem;
}

.slider:hover {
    opacity: .75;
    background: rgb(177, 177, 177);
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 13px;
    height: 13px;
    background: var(--ion-orange);
    cursor: pointer;
    border-radius: 50%
}

.slider::-moz-range-thumb {
    appearance: none;
    width: 13px;
    height: 13px;
    background: var(--ion-orange);
    cursor: pointer;
    border-radius: 50%
}

.flex-topcenter {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 14px;
}

//--------------- vendor

.grey-dark {
    color: rgb(133, 133, 133)
}


.top-profile-img {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    margin-left: 0.3rem;
    object-fit: cover;
}

.profile-img {
    border-radius: 50%;
    width: 31px;
    height: 31px;
    border: 1px solid #a2a2a2;
}

.section-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: var(--ion-font-body);
    font-weight: 600;
}

@mixin tg {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    hr {
        height: 4px;
        border: 0;
        border-radius: 5px;
        background-color: var(--ion-orange);
        width: 40px;
    }
}

.title-g {
    align-items: flex-start;
    @include tg;
}

.title-g-center {
    align-items: center;
    @include tg;
}

.cap {
    text-transform: capitalize !important;
}

.uppercase {
    text-transform: uppercase;
}

.flex-row-center-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.flex-top {
    display: flex;
    align-items: flex-start;
}

.line-height1-6 {
    line-height: 1.6rem;
}

.brown-bg {
    background-color: var(--ion-orange-dark);
}

.reviews-nbr {
    background-color: #ffffff;
    padding: 0 0.3rem;
    font-weight: 600;
    border-radius: 4px;
    font-size: var(--ion-font-body-smaller);
}

.err {
    color: var(--ion-red);
    font-size: var(--ion-font-body-small);
    margin-top: .4rem;
    display: flex;

    ion-icon {
        font-size: 1.13rem !important;
        margin-right: 5px !important;
    }
}

.margin-bot-05 {
    margin-bottom: .5rem;
}

.margin-bot-1 {
    margin-bottom: 1rem;
}

.margin-bot-1-5 {
    margin-bottom: 1.5rem;
}

.margin-bot-2 {
    margin-bottom: 2rem;
}

.margin-bot-3 {
    margin-bottom: 3rem;
}

.margin-bot-4 {
    margin-bottom: 4rem;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flex-wrap-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.max-width-1200 {
    max-width: 1200px;
    margin: 0 auto;
}

.line {
    border-top: 4px solid var(--ion-orange);
    width: 70px;
    margin: 0.5rem auto .4rem auto;
    height: 5px;
}

.line-left {
    border-top: 4px solid var(--ion-orange);
    width: 80px;
    height: 5px;
}


.h {
    font-weight: 600;
}

.sharp {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flex-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.under-const {
    color: grey;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-width-1000 {
    max-width: 1000px;
    margin: 0 auto;
    width: 94%
}

.box-width-1200 {
    max-width: 1200px;
    margin: 0 auto;
    width: 98%
}

.div-dot {
    width: 13px !important;
    height: 13px !important;
    border-radius: 50% !important;
    background-color: var(--ion-green);
    min-width: 13px !important;
    margin: auto !important;
    position: relative;
    bottom: -1.2rem;
    padding: unset !important;
}

.div-dot-2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--ion-green);
    margin: 0 1rem
}

.iinput {
    padding: 0;
    border: 1px solid rgb(192, 192, 192);
    border-radius: 6px;
    color: black;

    ion-input {
        width: 100%;
        --background: white;
        color: black;
    }
}

.max-width-1100 {
    max-width: 1100px;
    margin: 0 auto;
}

.menu-icon {
    border: 1px solid #b4b4b4;
    border-radius: 50%;
    padding: 0.2rem;
    color: black;
    height: 25px;
    width: 25px;

}


.submit-checkout {
    text-align: center;
    border-radius: 10px 10px 0 0;
    width: 236px;
    background-color: #ededed;
    padding: 0.5rem 0 1rem 0;
}

.search-zip {
    margin: -0.5rem 1rem 0 0;
    border: 1px solid #e3e3e3;
    border-radius: 7px;
    background-color: #fbfbfb;
}

.top-zip-rectangle {
    width: 110px;
    margin: 0.3rem 0.7rem 0;
    padding: 0.2rem;
    border: 1px solid var(--ion-orange);
    border-radius: 4px;
}

.enter-zip-box {
    margin: 2rem auto;
    max-width: 600px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 7px;
    padding: .5rem;
    background-color: white;
}

.nutrition {
    font-size: var(--ion-font-body-smaller);

    .value {
        color: var(--ion-orange-dark);
        font-size: var(--ion-font-body-smaller);
    }
}

.tbc-title {
    max-width: 240px;
    font-weight: 500;
    font-size: var(--ion-font-body-small);
}

.sc-ion-searchbar-md-h {
    --background: rgb(245, 245, 245);
    --box-shadow: unset !important;
}

.searchbar-input.sc-ion-searchbar-md {
    border: 1px solid #cbcbcb !important;
    border-radius: 4px !important;
}

table {
    width: 100% !important;

    td,
    th {
        padding: 5px !important;
        border-spacing: 1px !important;
    }

    tr {
        border: 1px solid rgb(212 220 235) !important;
        background-color: #fffcf7;
        height: 35px;
    }

    div {
        text-align: left;
        padding-top: 1rem;
    }
}

.tbc-cat-bg {
    background-color: #eeeded;
}

.page-size {
    max-width: 1600px;
    margin: 4rem auto 0 auto;
    width: 100%;
}

.page-size-header {
    max-width: 1600px;
    margin: 0 auto;
}

.app-meal {
    // margin-right: auto;
    // margin-left: auto;

    // margin-right: 0.6rem;
    // margin-left: 0.6rem;
}

.app-meal-2 {
    margin-right: auto;
    margin-left: auto;
}

.meal-title {
    font-size: var(--ion-font-body);
    font-family: var(--ion-font-family-semibold);
}

.topnav-mob {
    display: none;

    ion-icon {
        font-size: 343m;
    }
}

.review-msg {
    background-color: #fff3e0 !important;
    padding: 1rem .6rem;
    margin-right: .9rem;
    border-radius: 10px;
    margin-right: 6rem;
    min-height: 80px;
}

.review-msg-reply {
    background-color: #c3f4ff !important;
    padding: 1rem 0.6rem;
    margin-right: 0.9rem;
    border-radius: 10px;
    margin-left: 6rem;
    min-height: 50px;
}


.scroll-y {
    overflow-y: hidden !important;
}

.b-menu {
    font-size: var(--ion-font-body-bigger);
}

.nav-bot {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    z-index: 1000;
    height: 75px;
    background: white;
    border-top: 1px solid #cdcdcd;

    img {
        height: 25px;
        width: 25px;
    }

    div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        color: black;
        font-family: 'poppins-bold';
        margin-top: .4rem;
        cursor: pointer;
    }

}

.i-icon {
    font-size: 1.3rem !important;
    color: #4c4c4c;
}

.i-icon-2 {
    font-size: 1.5rem !important;
    color: #4c4c4c;
}

.i-icon-3 {
    font-size: 1rem !important;
    margin-right: 0.15rem;
    color: #000000;
}

.os {
    font-family: var(--ion-font-family-oswald);
    text-transform: uppercase;
}

.os-bold {
    font-family: var(--ion-font-family-oswald-bold);
}

.os-regular {
    font-family: var(--ion-font-family-oswald-bold);
}

.flex-rowreverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.marg-top-var {
    margin-top: 4rem;
}

.dish-img {
    height: 130px;
    width: 160px;
    object-fit: cover;
    border-radius: 10px 0;
    border: 1px solid #d9d9d9;
}

.template-container {
    max-width: 1500px;
    margin: 1rem auto 6rem auto;
    padding: .2rem;
    background-color: white;
    border-radius: 7px;
}

.template-container-f5 {
    max-width: 1500px;
    margin: 1rem auto 6rem auto;
    padding: .2rem;
    background-color: #f5f5f5;
    border-radius: 7px;
}

form {
    max-width: 750px;
    margin-right: auto;
    margin-left: auto;
}

.show-small-header {
    display: none;
}

.show-small-row {
    display: none;
}

@media (max-width: 991px) {

    .template-container {
        margin: 0.2rem .2rem 6rem .2rem;
        background-color: transparent;
    }

    .show-big-header {
        display: none;
    }

    .show-small-header {
        display: block;
    }

    .show-small-row {
        display: flex;
    }

    ion-content {
        --background: white;
    }

    .top-zip-rectangle {
        width: unset;
        margin: unset;
        margin-right: unset;
        padding: 0.4rem 10px 0.4rem 2px;
    }

    .col-box-2-card {
        border-radius: 10px;
        margin: 1rem 0.4rem;
        box-shadow: 2px 2px 5px #c5c5c5;
        background-color: white;
        max-width: 420px;
        min-width: 300px;
        width: unset;
    }

    h5,
    h6 {
        font-family: var(--ion-font-family-semibold);
        margin-top: 0;
        margin-bottom: 0;
        font-size: var(--ion-font-body-small);
        text-align: center;
    }

    .marg-top-var {
        margin-top: 2rem;
    }


    .review-msg {
        margin-right: 2rem;
    }

    .review-msg-reply {
        margin-left: 2rem;
    }

    .f-smaller {
        font-size: var(--ion-font-body-smaller);
    }

    .f-small {
        font-size: var(--ion-font-body-small);
    }

    .f-regular {
        font-size: var(--ion-font-mobile-body);
    }

    .f-big {
        font-size: var(--ion-font-mobile-body-big);
    }

    .f-big {
        font-size: var(--ion-font-mobile-body-bigger);
    }

    .cart-img {
        height: 70px;
        width: 50px;
        object-fit: cover;
        border-radius: 5px;
        margin: 0 0.4rem;
    }

    .col-box-tbc {
        border-radius: 10px;
        margin: 1.2rem 0.1rem;
        box-shadow: 0 0 2px #c5c5c5;
        background-color: white;
        width: 180px;
        font-size: var(--ion-font-body-small);
    }

    .c-btn {
        padding: 0.3rem !important;
        min-width: 1.5rem !important;
    }

    .show-me {
        display: none;
    }

    .page-size {
        margin: 0 auto 1rem auto;
        min-width: 300px;
        padding: 0.4rem;
    }

    .slider {
        width: 110px;
    }

    .dish-img {
        height: 115px !important;
        width: 120px !important;
        object-fit: cover;
        border: 1px solid #d9d9d9;
    }

    .vendor-image {
        width: 30px !important;
        height: 30px !important;
    }

    .col-box {
        box-shadow: unset;
        border: 1px solid rgb(201, 201, 201);
        margin: 1rem 0;
        padding: .4rem;
    }


    .meal-title {
        font-size: var(--ion-font-body-small);
    }

    .cat-title {
        font-size: var(--ion-font-body-small);
        text-align: center;
    }

    .i-icon {
        font-size: 1.5rem !important;
        color: #4c4c4c;
    }

    .i-icon-2 {
        font-size: 1.7rem !important;
        color: #4c4c4c;
    }

    .tag-item {
        font-size: var(--ion-font-body-smaller);

        img {
            object-fit: contain;
            width: 50px;
            height: 50px;
            margin: 0.3rem 0.2rem;
            max-width: unset;
            border-radius: 50%;
            box-sizing: border-box;
            // border: 1px solid #bdb3b3;
            cursor: pointer;
        }
    }

    .tag-item-tbc {
        font-size: var(--ion-font-body-smaller);

        img {
            object-fit: cover;
            width: 50px;
            height: 50px;
            max-width: unset;
            border-radius: 50%;
            box-sizing: border-box;
            //   border: 1px solid #bdb3b3;
            cursor: pointer;

        }
    }


    .calendar {
        div {
            min-width: 65px;
            height: 60px;
        }
    }

    select {
        background-color: #fcfcfc;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #d5d4d4;
        padding: 0.2rem;
        margin-top: 0.3rem;
        margin-left: 0.5rem;
        font-size: var(--ion-font-body-smaller);
        min-width: unset
    }
}

@media (min-width: 991px) {

    #mobile-menu {
        display: none;
    }

    #main-div {

        .div-title {
            font-size: 40px;
        }

        p {
            font-size: var(--ion-font-body);
            line-height: 1.5;
        }
    }
}

@media (max-width: 991px) {
    .topnav {
        display: none;
    }

    .topnav-mob {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media(max-width: 768px) {
    .ii {
        width: 96%;
    }

    .app-meal {
        width: 100%;
    }
}

@media (max-width: 991px) {

    .app-meal {
        width: 500px;
    }

    .ng-form {
        margin: 0 !important;
    }

    .modal-wrapper.sc-ion-modal-md {
        width: 96%;
    }

    #choosePlan {
        flex-direction: column !important;
    }

    .left-meal {
        width: 100% !important;
    }

    #myTopnav {
        display: none;
    }

    #mobile-menu {
        display: block;
    }

    #main-div {
        .div-title {
            font-size: 25px;
        }

        p {
            font-size: var(--ion-font-body);
            line-height: 1.2;
        }
    }

    .outer-div {
        margin: 2rem .2rem 4rem .2rem !important;
    }

}

.f-g {
    font-size: 1rem;
}

@media (max-width: 991px) {
    .chef-mask {
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.9))
    }

    .f-g {
        font-size: .8rem;
    }

    .border-bot-mobile {
        border-bottom: 1px solid rgb(138, 138, 138);
    }



    .template-container-1 {
        padding: 1rem .5rem 2rem .5rem;
        width: unset;
    }

    .img-cover-square {
        width: 30px;
        height: 50px;

    }

    .cat-title {
        font-size: var(--ion-font-body-smaller);
    }
}

.btn-3d {
    border-radius: 50px;
    background-image: linear-gradient(#fad1b4, #ff6d03);
    padding: 14px 45px;
    color: #fff !important;
    text-shadow: 0px 4px 6px #ff0000;
    border: 4px solid #fff;
    font-family: var(--ion-font-family-light);
    font-size: var(--ion-font-body);
    box-shadow: 0px 3px 5px 0 #b9b9b9;
    position: relative;
    position: relative;

    &:hover {
        background-image: linear-gradient(#ff6d03, #fad1b4);
    }

    &::before {
        content: "";
        background: #fff;
        position: absolute;
        border-radius: 5px 5px 0px 0px;
        left: 17px;
        top: 4px;
        z-index: 1;
        width: 135px;
        height: 12px;
        opacity: 0.6;
        filter: blur(8px);
        -webkit-filter: blur(4px);
    }
}

.min-height {
    min-height: 500px;
}

.stripe {
    background-color: #ffffff;
    width: 35px;
    height: 35px;
    font-size: 1.3rem;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 0.5rem;
    color: #6058f7;
    display: flex;
    justify-content: center;
    align-items: cente;
}

.logo-margin-top {
    margin-top: 4rem;
}

.icon-reg {
    //  margin-left: 1.2rem;
}

@media (max-width: 991px) {
    .logo-margin-top {
        margin-top: 0.2rem;
    }
}

//-----------------------------------fonts----------------------------

.f0-6 {
    font-size: 0.6rem;
}

.f0-65 {
    font-size: 0.65rem;
}

.f0-7 {
    font-size: 0.7rem;
}

.f0-75 {
    font-size: 0.75rem;
}

.f0-8 {
    font-size: 0.8rem !important;
}

.f0-9 {
    font-size: 0.9rem !important;
}

.f0-95 {
    font-size: 0.95rem !important;
}

.f1-0 {
    font-size: 1rem;
}

.f1-05 {
    font-size: 1.05rem;
}

.f1-1 {
    font-size: 1.1rem !important;
}

.f1-2 {
    font-size: 1.2rem;
}

.f1-3 {
    font-size: 1.3rem;
}

.f1-4 {
    font-size: 1.4rem;
}

.f1-5 {
    font-size: 1.5rem;
}

.f1-6 {
    font-size: 1.6rem;
}

.f1-7 {
    font-size: 1.7rem;
}

.f1-8 {
    font-size: 1.8rem;
}

.f1-9 {
    font-size: 1.9rem;
}

.f2-0 {
    font-size: 2rem !important;
}

.f2-5 {
    font-size: 2.5rem !important;
}

.f3-0 {
    font-size: 3rem !important;
}

.f3-5 {
    font-size: 3.5rem !important;
}

.f4-0 {
    font-size: 4rem !important;
}

//-------------------------flex-------------------------------------

.flex {
    display: flex;
}

.f-row {
    flex-direction: row;
}

.f-col {
    flex-direction: column;
}

.f-just-center {
    justify-content: center;
}

.f-just-start {
    justify-content: flex-start;
}

.f-just-end {
    justify-content: flex-end;
}

.f-just-btw {
    justify-content: space-between;
}

.f-just-around {
    justify-content: space-around;
}

.f-just-evenly {
    justify-content: space-evenly;
}

.f-align-center {
    align-items: center;
}

.f-align-start {
    align-items: flex-start;
}

.f-align-end {
    align-items: flex-end;
}

.f-align-base {
    align-items: baseline;
}

.f-wrap {
    flex-wrap: wrap;
}

.f-nowrap {
    flex-wrap: nowrap;
}

//------------------------------------------------------------------------

ion-content {
    --background: #f5f5f5;
}

h1,
h2,
h3,
h4 {
    font-family: 'poppins-med';
    margin-top: 0;
    margin-bottom: 0;
}


h4 {
    font-family: "poppins-bold";
    font-size: 1.1rem;
}

h5,
h6 {
    font-family: 'poppins-bold';
    margin-top: 0;
    margin-bottom: 0;
}

body {
    color: black;
    font-family: var(--ion-font-family-light);
    font-size: var(--ion-font-body);
}

.orange-dark {
    color: red
}

.orange-med {
    color: var(--ion-col2);
}

.orange-light {
    color: var(--ion-col3);
}

//---- font-------------

.poppins-thin {
    font-family: 'poppins-thin';
}

.poppins {
    font-family: 'poppins';
}

.poppins-med {
    font-family: 'poppins-med';
}

.poppins-bold {
    font-family: 'poppins-bold';
}

select {
    background-color: white;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #d5d4d4;
    padding: 0.2rem;
    min-width: 140px;
    font-size: 14px;
}

.mob-btn {
    max-width: 200px;
    border-radius: 5px;
    height: 36px;
    cursor: pointer;
    color: black;
    background-color: white;
    box-shadow: 3px 3px 10px #e1e1e1;
    border: 1px solid #e7e6e6;
}

.orange-dark-bg {
    color: white;
    background-color: var(--ion-col1);
    box-shadow: unset;
    max-width: 200px;
    border-radius: 5px;
    height: 36px;
    cursor: pointer;
}

.mob-card {
    border-radius: 7px;
    margin: 0.1rem 1.5rem 1rem 0;
    width: 235px;
    min-width: 150px;
    height: 96%;
    background-color: white;
    box-shadow: 3px 3px 7px #ead2d2;
    border: 1px solid #eaeaea;
}

.mob-cooked {
    position: relative;
    background-color: #ffffff;
    border-radius: 7px 7px 0 0;

    .vendor {
        height: 130px;
        width: 160px;
        object-fit: cover;
        border-radius: 5px 0 0 0;
        background-color: #9b8d8d;
        // mask-image: linear-gradient(to right, white 50%, transparent 100%);
        // -webkit-mask-image: linear-gradient(to right, white 50%, transparent 100%);
    }

    .truck {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px 0 10px 0;
        border: 1px solid #d9d9d9;
        background-color: #9b8d8d;
    }

    .dish {
        height: 100px;
        width: 100px;
        object-fit: cover;
        border-radius: 50%;
        border: 1px solid #d9d9d9;
        right: 0;
        top: 1rem;
        position: absolute;
    }
}

@media (min-width: 768px) {
    .mob-cooked {
        .vendor {
            //  width: 100%;
        }
    }
}

.mob-scrolling-list {
    display: flex;
    max-width: unset;
    overflow: auto;
}

.mob-bg {
    background-color: #f5f5f5 !important;
}

.auto {
    margin-left: auto;
    margin-right: auto;
}


.padding-top-1 {
    padding-top: 1rem;
}

.absolute {
    position: absolute;
}

// .input-wrapper.sc-ion-input-md input.sc-ion-input-md {
//     font-size: 13px;
// }

.dish-pic {
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px;
}

.my-cart-img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 50%;
    object-fit: cover;
}

.my-cart-img-2 {
    height: 100px;
    width: 100px;
    object-fit: contain;
    margin-right: 1rem;
    border-radius: 30%;
    object-fit: cover;
}

.svg {
    width: 20px;
    margin-right: .5rem;
}

.search-str {
    width: 100%;
    margin: 0.7rem;
    border-radius: 5px;
    height: 45px;
    border: 1px solid #cbcbcb;
    padding-left: .5rem;
    background: white;
}

.mob-cooked-1 {
    border-radius: 5px;
    margin: 0 1rem 2rem 0;
    min-width: 150px;
    background-color: white;
    border: 1px solid #d7d7d7;
    box-shadow: 1px 1px 6px #c9c9d5;
    width: 100%;
    height: 285px;

    .my-img {
        width: 100%;
        height: 210px;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
        cursor: pointer;
    }
}

.my-shadow {
    box-shadow: 3px 4px 8px #e3e3e3;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    padding: 0.5rem;
    background-color: white;
}

.my-top-pic {
    max-height: 350px;
    object-fit: cover;
    border-radius: 7px;
}

.caticon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffdb67;
    padding: 5px;

    ion-icon {
        font-size: 1.1rem !important;
    }
}

.cart-total-badge {
    position: absolute;
    top: -10px;
    right: -5px;
    background-color: #ffa704;
    font-weight: 600;
    border-radius: 50%;
    color: white;
    font-size: 12px;
    min-width: 18px;
    height: 18px;
    padding: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.marg-scroll {
    margin-top: 1rem
}

.back-btn {
    height: 34px;
}

.back-btn-2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 34px;
    z-index: 3000;
}

.view-marg-top-1 {
    margin-top: 1rem;
}

.sq-order {
    max-width: 1100px;
    margin: 1rem auto;
    background-color: white;
    border: 1px solid #f5f5f5;
    box-shadow: 4px 4px 15px #cbcbcb;
    border-radius: 6px;
    padding: .6rem .6rem 1rem .6rem;
}

@media (min-width: 768px) {
    .my-section {
        // background-color: white;
        // border-radius: 7px;
        // padding: 1.5rem;
    }
}

.my-item {
    background: white;
    border-radius: 10px;
    box-shadow: 2px 2px 7px #dbd1d1;
    padding: .5rem .7rem;
    box-shadow: 5px 5px 10px #d6d6d6;
}

.order-img {
    height: 40px;
    width: 40px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 0.6rem;
    border: 1px solid #d6d6d6;
}

.totals {
    border-radius: 1px solid #c7c7c7;
    padding: 0.4rem 1rem 1rem 1rem;
    border-radius: 6px;
    margin: 1rem auto;
    max-width: 600px;
    background-color: white;
    font-family: 'poppins-bol';

}

.my-body {
    margin: 0 auto 1rem auto;
    background: white;
    padding: .6rem 1rem;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #e5e5e5;
}

.toast-scheme {
    .toast-message {
        color: white !important;
    }

    .toast-container {
        color: white !important;

    }

    .toast-md {
        color: white !important;

    }

    color: white !important;

    .ion-toast {
        color: white !important;
    }
}

.pickup {
    background-color: #e3e3e3;
    font-family: "poppins-bold";
    padding: 0.5rem 2rem;
    border-radius: 20px;
    margin: .5rem 0;
}

.big-order-template {
    max-width: 1160px;
    border: 1px solid #dedede;
    background-color: #f5f5ff;
    margin: 2rem auto;
}


.my-sq-date {
    margin: 0 auto 1rem auto;
    max-width: 450px;
    background: white;
    padding: 1rem .1rem;
    border-radius: 6px;
    box-shadow: 3px 3px 6px #e5e5e5;
    text-align: center;
    font-size: .9rem;
    font-family: 'poppins-bold';
}

.my-sq-name {
    background: white;
    padding: .2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: .8rem solid #f5f5f5;
    border-radius: 6px;
}

.my-sq-body {
    background: white;
    padding: .6rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #f5f5f5;
}

.ios-margin {
    margin-top: 45px !important;
}

@media (max-width: 768px) {
    .marg-scroll {
        margin-top: .5rem
    }

    .view-marg-top-1 {
        margin-top: 0;
        background-color: white;
    }
}